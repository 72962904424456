$color-blossom: #2c8898;
$color-fade: #982c61;

$color-bg: #f9f9f9;
$color-bg-alt: #f1f1f1;

$color-text-fallback: #313131;
$color-text: rgba(0, 0, 0, 0.8);

$color-blossom-dark: #ffffff;
$color-fade-dark: #c9c9c9;
$color-bg-dark: #222222;
$color-bg-alt-dark: #4a4a4a;
$color-text-dark: #c9c9c9;
/* CHANGE IN SAKURAMAIN */

$font-size-base: 1.8rem;
$font-family-sans-serif: Verdana, Geneva, sans-serif;
$font-family-serif: serif;

@import "normalize";
@import "boiler";
@import "sakuramain";

nav ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  li {
    float: left;
    margin-bottom: 0;
    a {
      border-bottom: none;
      display: block;
      padding: 14px 16px;
      text-align: center;
      text-decoration: none;
      &:hover {
        border-bottom: none;
        color: $color-bg;
        &:not(.active) {
          background-color: $color-blossom;
        }
      }
    }
    .active {
      background-color: $color-blossom;
      color: $color-bg;
    }
  }
}

.site-title {
  margin-bottom: 0;
}

.site-subtitle {
  font-style: italic;
  margin-top: 0.33em;
  text-transform: lowercase;
}

.site-header {
  margin-bottom: 1em;
  &::after {
    border-bottom: 0.125em solid $color-blossom;
    content: "";
    display: block;
    width: 4em;
  }
}

.inlinecode {
  display: inline;
  padding: 0;
}

code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.inlinecode code {
  display: inline;
}

body {
  color: $color-text-fallback;
  color: $color-text;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.7rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
}

#skip a {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
}

.h-card p {
  margin: 0;
}

@media (prefers-color-scheme: dark) {
    nav ul {
        li {
            a {
                &:hover {
                    color: $color-bg-dark;
                    &:not(.active) {
                        background-color: $color-blossom-dark;
                    }
                }
            }
            .active {
                background-color: $color-bg-alt-dark;
            }
        }
    }
    .site-header::after {
        border-bottom: 0.125em solid $color-blossom-dark;
    }
    body {
        color: $color-text-dark;
    }
}

